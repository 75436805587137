import {MainPage} from "./pages/Main"

import "./App.scss"

function App() {

  return (
    <div className="App">
      <MainPage />
    </div>
  );
}

export default App;
